import React from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import {
  Section,
  SectionText,
  TwoColumn,
  TopHeader,
} from "../../components/Section"
import Seo from "../../components/Seo"

export default function hazardousWasteDisposal() {
  return (
    <Layout>
      <Seo
        title="Hazardous Waste Disposal"
        description="A&D Demolition is fully licensed company that specializes in hazardous waste disposal services in Vancouver. Our work is environment friendly as well."
      />
      <Section>
        <TopHeader>
          <h1>Hazardous Waste Disposal</h1>
        </TopHeader>
        <TwoColumn>
          <SectionText>
            <p>
              Hazardous waste disposal services are required by homeowners or
              business proprietors after demolition or remodelling of their
              property as they don’t know where and how to dispose hazardous
              materials such as asbestos. For that reason, we do the work for
              you.{" "}
            </p>
          </SectionText>
          <SectionText>
            <p>
              We ensure that all asbestos waste and any other contaminated waste
              is separated and arranged in sealed containers and labelled
              properly. A prior settlement should be done with the authorities
              to transport any hazardous waste to assigned landfills. For
              example, following the Safe Work Practices for Handling Asbestos,
              this waste must be double bagged in 6 mil poly bags, sealed with
              duct tape and label.{" "}
            </p>
          </SectionText>
        </TwoColumn>
      </Section>
      <Section>
        <StaticImage
          src="../../images/waste-disposal.jpg"
          alt="A jobsite with waste material in the background"
          placeholder="blurred"
          aspectRatio={4 / 2.1}
          layout="fullWidth"
          style={{
            boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.15)",
            borderRadius: "5px",
          }}
        />
      </Section>
      <Section>
        <TwoColumn>
          <SectionText>
            <p>
              Our personnel are <em>trained to work safely</em> and to make the
              process of your construction project as peaceful as possible. We
              will make sure that all paperwork is done for you on time, from
              registering the activities with the Environmental Agency, to
              supplying the delivery order from the approved disposal site,
              offering you the tranquility that the hazardous waste has been
              responsibly and legally disposed of.{" "}
            </p>
          </SectionText>
          <SectionText>
            <p>
              There are also no hazardous materials that are prohibited as waste
              and can be refused for disposal as cardboard, drywall and metal.
              These materials should be removed and can be taken as recycled
              components by landfills.{" "}
            </p>
            <p>
              Our experience enables us to do a <em>hassle free</em> job, always{" "}
              <em>considering the environment</em> and trying to{" "}
              <em>recover all materials that can be recycled</em> and reused as
              PCB ballasts and mercury thermostats that are dangerous materials
              accepted for recycling. At A&D Demolition, we take care of every
              step from demolition to transport and disposal of all waste and
              hazardous materials, making your safety our number one
              responsibility, offering a quality job at a reasonable price. Do
              not hesitate in contact us!
            </p>
          </SectionText>
        </TwoColumn>
      </Section>
    </Layout>
  )
}
